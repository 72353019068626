import { VBtn } from 'vuetify/lib/components/VBtn';
import { VSlideGroup } from 'vuetify/lib/components/VSlideGroup';
import { VSlideItem } from 'vuetify/lib/components/VSlideGroup';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.item.motivation.show)?_c('div',{staticStyle:{"min-height":"auto"},domProps:{"innerHTML":_vm._s(_vm.htmlData(_vm.item.motivation.content))}}):_vm._e(),(_vm.item.horizontal&&_vm.content.show_constructor_categories)?_c('div',{staticStyle:{"padding":"10px 0"}},[_c(VSlideGroup,{staticClass:"slide_listing",attrs:{"center-active":""},model:{value:(_vm.active_listing_tab),callback:function ($$v) {_vm.active_listing_tab=$$v},expression:"active_listing_tab"}},_vm._l((_vm.content.content.listing),function(listingItem,index){return _c(VSlideItem,{key:index,scopedSlots:_vm._u([{key:"default",fn:function(ref){
var active = ref.active;
var toggle = ref.toggle;
return [_c(VBtn,{staticClass:"mx-2",staticStyle:{"border":"none","border-radius":"12px"},style:(("background:" + (_vm.active_listing_tab === index
              ? _vm.item.styles.horizontal_active_background
              : _vm.item.styles.horizontal_background) + ";color:" + (_vm.active_listing_tab === index
              ? _vm.item.styles.horizontal_active_color
              : _vm.item.styles.horizontal_color) + ";")),attrs:{"input-value":_vm.active_listing_tab === index,"outlined":"","small":"","depressed":""},on:{"click":function($event){toggle;
            _vm.scrollToTitle(index);}}},[_vm._v(" "+_vm._s(_vm.strip(listingItem.title))+" ")])]}}],null,true)})}),1)],1):_vm._e(),(_vm.item.styles.listing_vector === 'horizontal')?_c('div',{staticStyle:{"overflow":"hidden"}},_vm._l((_vm.content.content.listing.filter(
        function (x) { return x.hide; }
      )),function(listingItem,index){return _c('div',{key:index,staticStyle:{"margin-bottom":"15px","display":"flex","flex-direction":"column"}},[_c('div',{ref:("listing" + index),refInFor:true,staticStyle:{"height":"auto","min-height":"auto","padding":"5px"},domProps:{"innerHTML":_vm._s(_vm.htmlData(listingItem.title))}}),_c('swiper',{ref:"mySwiper",refInFor:true,attrs:{"options":_vm.swiperOptions},on:{"click-slide":function (slideIndex) { return _vm.changeListingPage(index, slideIndex, _vm.type); }}},_vm._l((listingItem.content.filter(
            function (x) { return x.hide; }
          )),function(listing,listingIndex){return _c('swiper-slide',{key:listingIndex,staticClass:"listing listing__item",style:(_vm.displayType(_vm.item.styles, listing) +
            "border-radius:" + (_vm.item.styles.borderListingRadius) + "px;padding:0;min-height:" + _vm.listingHeight + ";width:" + (_vm.content.styles.width) + "px;"),attrs:{"id":("listing_" + index + "_" + listingIndex)}},[(listing.preview)?_c('div',{class:("preview__inside__" + index + "__" + listingIndex),staticStyle:{"z-index":"-1","position":"absolute","width":"100%","height":"100%"}}):_vm._e(),(listing.main_img && _vm.item.styles.displayType !== 'inside')?_c('div',{class:("listing__item__img__" + index + "__" + listingIndex),staticStyle:{"float":"left","margin-right":"5px"},style:(("overflow:hidden;width:" + (_vm.item.styles.displayType === 'horizontal'
                ? _vm.item.styles.listing_img_width + 'px'
                : '100%') + ";height:" + (_vm.item.styles.listing_img_height) + "px;overflow:hidden;"))},[_c('img',{staticStyle:{"width":"100%"},style:(("height:" + (_vm.item.styles.listing_img_height) + "px;width:" + (_vm.item.styles.displayType === 'horizontal'
                  ? _vm.item.styles.listing_img_width + 'px'
                  : '100%') + ";")),attrs:{"src":listing.main_img,"alt":""}})]):_vm._e(),(_vm.content.styles.content_position === 'center')?_c('div',{style:(("height:" + (_vm.content.styles.lining_height) + "px;width:" + (_vm.content.styles.lining_width) + "%;font-family:" + (_vm.content.styles.fontFamily) + ";display:" + (_vm.item.styles.displayType === 'inside' ? 'flex' : 'block') + ";justify-content:center;align-items:center;text-align:" + (_vm.item.styles.displayType !== 'horizontal' ? 'center' : 'left') + ";padding-left: " + (_vm.item.styles.displayType !== 'horizontal' ? '0' : '10px') + ";margin-left: " + (_vm.item.styles.displayType !== 'horizontal' ? '0' : '10px') + ";"))},[_c('div',{style:(("height:100%;width:100%;border-radius:" + (_vm.item.styles.borderListingRadius) + "px;padding:" + (_vm.item.styles.displayType === 'inside' ? _vm.content.styles.lining_padding+'px' : '0') + ";background:" + (_vm.item.styles.displayType === 'inside'
                  ? _vm.content.styles.lining_color
                  : 'transparent') + ";"))},[_c('p',{staticClass:"listing__name",style:(("color:" + (listing.name_color) + ";font-family:" + (_vm.content.styles.fontFamily) + ";font-size:" + (_vm.content.styles.nameFontSize) + "px;"))},[_vm._v(" "+_vm._s(listing.name)+" ")]),(listing.show_descr)?_c('p',{staticClass:"listing__text",staticStyle:{"margin-top":"10px"},style:(("color:" + (listing.descr_color) + ";font-family:" + (_vm.content.styles.fontFamilyDescription) + ";font-size:" + (_vm.content.styles.descriptionFontSize) + "px;"))},[_vm._v(" "+_vm._s(listing.descr)+" ")]):_vm._e()])]):_vm._e()])}),1)],1)}),0):_c('div',[_c('draggable',{attrs:{"options":{disabled: _vm.isMobile},"draggable":".draggable_group","list":_vm.content.content.listing.filter(function (x) { return x.hide; })}},_vm._l((_vm.content.content.listing.filter(
        function (x) { return x.hide; }
      )),function(listingItem,index){return _c('div',{key:index,staticClass:"draggable_group",staticStyle:{"margin-bottom":"15px","display":"flex","flex-direction":"column"}},[_c('div',{ref:("listing" + index),refInFor:true,staticStyle:{"height":"auto","min-height":"auto","margin-bottom":"10px"},domProps:{"innerHTML":_vm._s(_vm.htmlData(listingItem.title))}}),_c('draggable',{staticStyle:{"height":"auto","min-height":"auto"},attrs:{"options":{disabled: _vm.isMobile},"draggable":".listing__item","list":listingItem.content}},_vm._l((listingItem.content.filter(
            function (x) { return x.hide; }
          )),function(listing,listingIndex){return _c('div',{key:listingIndex},[(_vm.content.styles.content_position === 'top' && _vm.item.styles.displayType === 'inside')?_c('div',{staticStyle:{"height":"100%"},style:(("font-family:" + (_vm.content.styles.fontFamily) + ";display:" + (_vm.item.styles.displayType === 'inside' ? 'flex' : 'block') + ";justify-content:center;align-items:center;text-align:" + (_vm.item.styles.displayType !== 'horizontal' ? 'center' : 'left') + ";padding-left: " + (_vm.item.styles.displayType !== 'horizontal' ? '0' : '10px') + ";margin-left: " + (_vm.item.styles.displayType !== 'horizontal' ? '0' : '10px') + ";"))},[_c('div',{style:(("border-radius:" + (_vm.item.styles.borderListingRadius) + "px;padding:" + (_vm.item.styles.displayType === 'inside' ? '15px' : '0') + ";background:" + (_vm.item.styles.displayType === 'inside'
                  ? _vm.content.styles.lining_color
                  : 'transparent') + ";"))},[_c('p',{staticClass:"listing__name",style:(("color:" + (listing.name_color) + ";font-family:" + (_vm.content.styles.fontFamily) + ";font-size:" + (_vm.content.styles.nameFontSize) + "px;"))},[_vm._v(" "+_vm._s(listing.name)+" ")]),(listing.show_descr)?_c('p',{staticClass:"listing__text",staticStyle:{"margin-top":"10px"},style:(("color:" + (listing.descr_color) + ";font-family:" + (_vm.content.styles.fontFamilyDescription) + ";font-size:" + (_vm.content.styles.descriptionFontSize) + "px;"))},[_vm._v(" "+_vm._s(listing.descr)+" ")]):_vm._e()])]):_vm._e(),_c('div',{staticClass:"listing listing__item",staticStyle:{"position":"relative","display":"block","margin-bottom":"15px","padding":"5px"},style:(_vm.displayType(_vm.item.styles, listing) +
            "overflow:hidden;border-radius:" + (_vm.item.styles.borderListingRadius) + "px;padding:0;min-height:" + _vm.listingHeight + ";box-shadow:0 " + (_vm.content.styles.shadow_size) + "px 6px " + (_vm.content.styles.shadow_color) + ";border:" + (_vm.content.styles.border_size) + "px solid " + (_vm.content.styles.border_color) + ";"),attrs:{"id":("listing_" + index + "_" + listingIndex)},on:{"click":function($event){$event.preventDefault();return _vm.changeListingPage(index, listingIndex, _vm.type)}}},[(listing.preview)?_c('div',{class:("preview__inside__" + index + "__" + listingIndex),staticStyle:{"z-index":"-1","position":"absolute","width":"100%","height":"100%"}}):_vm._e(),(listing.main_img && _vm.item.styles.displayType !== 'inside')?_c('div',{class:("listing__item__img__" + index + "__" + listingIndex),staticStyle:{"float":"left","margin-right":"5px"},style:(("overflow:hidden;width:" + (_vm.item.styles.displayType === 'horizontal'
                ? _vm.item.styles.listing_img_width + 'px'
                : '100%') + ";height:" + (_vm.item.styles.listing_img_height) + "px;"))},[_c('img',{staticStyle:{"width":"100%"},style:(("height:" + (_vm.item.styles.listing_img_height) + "px;width:" + (_vm.item.styles.displayType === 'horizontal'
                  ? _vm.item.styles.listing_img_width + 'px'
                  : '100%') + ";")),attrs:{"src":listing.main_img,"alt":""}})]):_vm._e(),(_vm.content.styles.content_position === 'center'&& _vm.item.styles.displayType === 'inside' || _vm.item.styles.displayType !== 'inside')?_c('div',{style:(("height:" + (_vm.item.styles.displayType === 'inside'?_vm.content.styles.lining_height+'px':'100%') + ";width:" + (_vm.item.styles.displayType === 'inside'?_vm.content.styles.lining_width+'%':'100%') + ";font-family:" + (_vm.content.styles.fontFamily) + ";\n                text-align:" + (_vm.item.styles.displayType !== 'horizontal' ? 'center' : 'left') + ";padding-left: " + (_vm.item.styles.displayType !== 'horizontal' ? '0' : '10px') + ";margin-left: " + (_vm.item.styles.displayType !== 'horizontal' ? '0' : '10px') + ";"))},[_c('div',{style:(_vm.checkBorderRadius()+";display:" + (_vm.item.styles.displayType === 'inside'?'flex':'block') + ";align-items:center;justify-content:center;flex-direction:column;width:100%;height:100%;padding:" + (_vm.item.styles.displayType === 'inside' ? _vm.content.styles.lining_padding+'px' : '0') + ";background:" + (_vm.item.styles.displayType === 'inside'
                  ? _vm.content.styles.lining_color
                  : 'transparent') + ";")},[_c('p',{staticClass:"listing__name",style:(("color:" + (listing.name_color) + ";font-family:" + (_vm.content.styles.fontFamily) + ";margin-bottom:0;font-size:" + (_vm.content.styles.nameFontSize) + "px;"))},[_vm._v(" "+_vm._s(listing.name)+" ")]),(listing.show_descr)?_c('p',{staticClass:"listing__text",staticStyle:{"margin-top":"10px"},style:(("color:" + (listing.descr_color) + ";font-family:" + (_vm.content.styles.fontFamilyDescription) + ";margin-bottom:0;font-size:" + (_vm.content.styles.descriptionFontSize) + "px;"))},[_vm._v(" "+_vm._s(listing.descr)+" ")]):_vm._e()])]):_vm._e()]),(_vm.content.styles.content_position === 'bottom'&& _vm.item.styles.displayType === 'inside')?_c('div',{staticStyle:{"height":"100%"},style:(("font-family:" + (_vm.content.styles.fontFamily) + ";display:" + (_vm.item.styles.displayType === 'inside' ? 'flex' : 'block') + ";justify-content:center;align-items:center;text-align:" + (_vm.item.styles.displayType !== 'horizontal' ? 'center' : 'left') + ";padding-left: " + (_vm.item.styles.displayType !== 'horizontal' ? '0' : '10px') + ";margin-left: " + (_vm.item.styles.displayType !== 'horizontal' ? '0' : '10px') + ";"))},[_c('div',{style:(("border-radius:" + (_vm.item.styles.borderListingRadius) + "px;padding:" + (_vm.item.styles.displayType === 'inside' ? '15px' : '0') + ";background:" + (_vm.item.styles.displayType === 'inside'
                  ? _vm.content.styles.lining_color
                  : 'transparent') + ";"))},[_c('p',{staticClass:"listing__name",style:(("color:" + (listing.name_color) + ";font-family:" + (_vm.content.styles.fontFamily) + ";font-size:" + (_vm.content.styles.nameFontSize) + "px;"))},[_vm._v(" "+_vm._s(listing.name)+" ")]),(listing.show_descr)?_c('p',{staticClass:"listing__text",staticStyle:{"margin":"0"},style:(("color:" + (listing.descr_color) + ";font-family:" + (_vm.content.styles.fontFamilyDescription) + ";font-size:" + (_vm.content.styles.descriptionFontSize) + "px;"))},[_vm._v(" "+_vm._s(listing.descr)+" ")]):_vm._e()])]):_vm._e()])}),0)],1)}),0)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }